import Logo from '@components/Logo'
import Icon, { IconsNames } from '@components/Icon'
import Link from 'next/link'

const networks: { name: IconsNames; url: string }[] = [
  // { name: 'facebook', url: 'https://www.facebook.com/AnotherNoob/' },
  { name: 'twitter', url: 'http://twitter.com/linkstrifer' },
  { name: 'instagram', url: 'https://www.instagram.com/linkstrifer/' },
  { name: 'tiktok', url: 'https://www.tiktok.com/@an0thernoob' },
  { name: 'patreon', url: 'https://www.patreon.com/linkstrifer' },
]

export default function Footer() {
  return (
    <footer className="text-gray-600 body-font">
      <div className="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col">
        <Link href="/" passHref>
          <div className="cursor-pointer flex title-font font-medium items-center md:justify-start justify-center text-gray-900 hover:bg-purple-700 hover:bg-opacity-10 rounded-lg px-2">
            <Logo />
            <span className="ml-3 text-xl">AnotherNoob</span>
          </div>
        </Link>

        <p className="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">
          © 2021 AnotherNoob
        </p>

        <span className="inline-flex sm:ml-auto sm:mt-0 mt-4 justify-center sm:justify-start items-center">
          {networks.map(({ name, url }) => (
            <a
              className="text-gray-500 mx-1 p-3 hover:bg-purple-700 hover:bg-opacity-10 rounded-lg"
              href={url}
              key={name}
              rel="noopener noreferrer"
              target="_blank"
            >
              <span className="hidden">AnotherNoob en {name}</span>
              <Icon name={name} />
            </a>
          ))}
        </span>
      </div>
    </footer>
  )
}
