import Logo from '@components/Logo'
import Link from 'next/link'
import Copy from '@components/Copy'

const wallet = {
  metamask: '0x3981BDc7acf140B21fCafab30B6f6530c6bADbc1',
  ronin: 'ronin:634d966101c83c21b9919d35b6a0b41d496070ae',
}

export default function Header() {
  return (
    <header className="text-gray-600 body-font">
      <div className="container mx-auto flex flex-wrap p-5 flex-col sm:flex-row items-center justify-around">
        <Link href="/" passHref>
          <div className="cursor-pointer flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0 hover:bg-purple-700 hover:bg-opacity-10 rounded-lg px-2">
            <Logo />
            <span className="ml-3 text-xl">AnotherNoob</span>
          </div>
        </Link>

        <Copy
          className="my-2"
          value={wallet.metamask}
        >{`Metamask: ${wallet.metamask.slice(0, 10)}...${wallet.metamask.slice(
          -10,
        )}`}</Copy>

        <Copy
          className="my-2"
          value={wallet.ronin}
        >{`Ronin: ${wallet.ronin.slice(0, 10)}...${wallet.ronin.slice(
          -10,
        )}`}</Copy>

        {/* <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center">
          <a className="mr-5 hover:text-gray-900">First Link</a>
          <a className="mr-5 hover:text-gray-900">Second Link</a>
          <a className="mr-5 hover:text-gray-900">Third Link</a>
          <a className="mr-5 hover:text-gray-900">Fourth Link</a>
        </nav> */}
      </div>
    </header>
  )
}
