import Image from 'next/image'

export default function Logo() {
  return (
    <Image
      src="/images/logo.png"
      alt="Another Noob"
      width="64"
      height="64"
      className="w-10 h-10"
    />
  )
}
