import 'tailwindcss/tailwind.css'
import '@styles/globals.css'
import Head from 'next/head'
import Footer from '@components/Footer'
import Header from '@components/Header'
import MDXProvider from '@components/axie/wiki/mdx'
import { useRouter } from 'next/router'
import cx from 'classnames'
import NavBar from '@components/axie/wiki/nav-bar'
import { ModalProvider } from '@components/Modal/Context'

function Application({ Component, pageProps }) {
  const router = useRouter()

  const isAxieWiki = router.pathname.includes('/axie-infinity/wiki')

  const navBarClasses = cx(
    'md:col-span-1 md:order-2 md:sticky top-4 self-baseline',
  )

  const contentClasses = cx('md:col-span-2 md:order-1')

  return (
    <MDXProvider>
      <ModalProvider>
        <Head>
          <title>AnotherNoob</title>
          <link rel="manifest" href="/site.webmanifest" />

          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/images/favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/images/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/images/favicon/favicon-16x16.png"
          />
          <link
            rel="mask-icon"
            href="/images/favicon/safari-pinned-tab.svg"
            color="#aa66cc"
          />

          <meta name="msapplication-TileColor" content="#aa66cc" />
          <meta name="theme-color" content="#ffffff" />
        </Head>

        <div className={cx('container')}>
          <Header />

          <main
            className={cx('mx-4', {
              'grid md:grid-cols-3 gap-4 relative': isAxieWiki,
            })}
          >
            {isAxieWiki && (
              <div className={navBarClasses}>
                <NavBar />
              </div>
            )}

            {isAxieWiki ? (
              <div className={contentClasses}>
                <Component {...pageProps} />
              </div>
            ) : (
              <Component {...pageProps} />
            )}
          </main>

          <Footer />
        </div>
      </ModalProvider>
    </MDXProvider>
  )
}

export default Application
