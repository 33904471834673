import {
  AiOutlineUser as IconAuthor,
  AiOutlineClockCircle as IconDate,
  AiFillFacebook as IconFacebook,
} from 'react-icons/ai'

import {
  IoCopyOutline as IconClipboard,
  IoLogoTiktok as IconTikTok,
  IoClose as IconClose,
} from 'react-icons/io5'

import {
  FaDiscord as IconDiscord,
  FaInstagram as IconInstagram,
  FaPatreon as IconPatreon,
  FaTwitter as IconTwitter,
} from 'react-icons/fa'

const Icons = {
  author: IconAuthor,
  clipboard: IconClipboard,
  close: IconClose,
  date: IconDate,
  discord: IconDiscord,
  facebook: IconFacebook,
  instagram: IconInstagram,
  patreon: IconPatreon,
  tiktok: IconTikTok,
  twitter: IconTwitter,
}

export type IconsNames =
  | 'author'
  | 'clipboard'
  | 'close'
  | 'date'
  | 'discord'
  | 'facebook'
  | 'instagram'
  | 'patreon'
  | 'tiktok'
  | 'twitter'

type IconProps = {
  name: IconsNames
  className?: string
}

export default function Icon({ name, className }: IconProps) {
  const IconComponent = Icons[name]

  return <IconComponent className={className} />
}
