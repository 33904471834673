import React, { createElement, useEffect, useState } from 'react'
import cx from 'classnames'
import { toKebab } from '@utils/changeCase'
import Link from 'next/link'
import { default as NextImage } from 'next/image'

export function Heading({
  type = 'h1',
  children,
}: {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  children: React.ReactNode
}) {
  return createElement(type, {
    children,
    id: toKebab(children as string),
    className: cx('mb-4', 'mt-16', {
      'text-4xl text-primary font-bold mb-4': type === 'h1',
      'text-3xl text-primary font-bold mb-4': type === 'h2',
      'text-2xl text-primary font-bold mb-4': type === 'h3',
      'text-md text-primary font-bold mb-4': ['h4', 'h5', 'h6'].includes(type),
    }),
  })
}

export function Paragraph({ children }: { children: React.ReactNode }) {
  return <p className="mb-4">{children}</p>
}

export function UnorderedList({ children }: { children: React.ReactNode }) {
  return <ul className="mb-4 px-4 list-inside list-disc	">{children}</ul>
}

export function ListItem({ children }: { children: React.ReactNode }) {
  return <li className="mb-2 list-item">{children}</li>
}

export function Anchor({
  children,
  href,
}: {
  children: React.ReactNode
  href: string
}) {
  const isExternal = href.includes('http')

  return isExternal ? (
    <a href={href} rel="noopener noreferrer" target="_blank">
      <span className="underline text-primary font-bold">{children}</span>
    </a>
  ) : (
    <Link href={href} passHref>
      <span className="underline text-primary font-bold cursor-pointer">
        {children}
      </span>
    </Link>
  )
}

export function Italic({ children }: { children: React.ReactNode }) {
  return <em className="italic">{children}</em>
}

export function Table({ children }: { children: React.ReactNode }) {
  return <table className="mx-auto my-8">{children}</table>
}

export function TableCell({ children }: { children: React.ReactNode }) {
  return (
    <td className="p-2 border-2 border-primary border-opacity-20">
      {children}
    </td>
  )
}

export function TableHeading({ children }: { children: React.ReactNode }) {
  return (
    <th className="py-2 px-4 border-b-2 border-primary border-opacity-20">
      {children}
    </th>
  )
}

export function CustomImage({ src, alt }: { src: string; alt: string }) {
  const [size, setSize] = useState({
    height: 0,
    width: 0,
  })

  useEffect(() => {
    const image = new Image()
    image.src = src
    image.onload = () => {
      setSize({
        height: image.height,
        width: image.width,
      })
    }
  }, [src])

  return (
    <figure
      className="p-4 mx-auto relative"
      style={{ maxWidth: 600, width: size.width }}
    >
      <NextImage
        src={src}
        layout="responsive"
        width={size.width}
        height={size.height}
      />
      <figcaption className="p-2 text-secondary">{alt}</figcaption>
    </figure>
  )
}
