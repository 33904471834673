import { createContext, useReducer } from 'react'

type ModalsState = {
  modals: {
    [key: string]: {
      isOpen: boolean
    }
  }
}

export const ModalsContext = createContext<
  ModalsState & {
    openModal: (name: string) => void
    closeModal: (name: string) => void
  }
>({
  modals: {},
  openModal: (name: string) => {},
  closeModal: (name: string) => {},
})

const reducer = (
  state: ModalsState,
  { type, payload }: { type: string; payload: any },
) => {
  switch (type) {
    case 'OPEN_MODAL': {
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.name]: {
            isOpen: true,
          },
        },
      }
    }
    case 'CLOSE_MODAL': {
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.name]: {
            isOpen: false,
          },
        },
      }
    }
    default: {
      return state
    }
  }
}

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, {
    modals: {},
  })

  return (
    <ModalsContext.Provider
      value={{
        ...state,
        openModal: (name: string) => {
          dispatch({
            type: 'OPEN_MODAL',
            payload: {
              name,
            },
          })
        },
        closeModal: (name: string) => {
          dispatch({
            type: 'CLOSE_MODAL',
            payload: {
              name,
            },
          })
        },
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}
