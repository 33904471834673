import { ReactNode, useState } from 'react'
import cx from 'classnames'
import Icon from '@components/Icon'

export default function Copy({
  value,
  children,
  className,
  variant = 'secondary',
}: {
  value: string
  children: ReactNode
  className?: string
  variant?: 'primary' | 'secondary'
}) {
  const [copied, setCopied] = useState(false)

  return (
    <div
      className={cx(
        'cursor-pointer',
        'flex',
        'font-bold',
        'items-center',
        'justify-center',
        'overflow-ellipsis',
        'overflow-hidden',
        'px-4',
        'py-2',
        'relative',
        'rounded',
        'text-white',
        {
          'bg-primary': variant === 'primary',
          'bg-secondary': variant === 'secondary',
        },
        className,
      )}
      onClick={() => {
        navigator.clipboard.writeText(value)
        setCopied(true)

        setTimeout(() => setCopied(false), 3000)
      }}
    >
      <Icon className={cx('mr-2')} name="clipboard" />

      {children}

      {copied && (
        <span
          className={cx(
            'absolute',
            'inset-0',
            'flex',
            'justify-center',
            'items-center',
            'bg-green-600',
          )}
        >
          Copied!
        </span>
      )}
    </div>
  )
}
