import { useState } from 'react'
import { Anchor } from './typography'
import cx from 'classnames'

const options = [
  {
    label: 'Motivos de baneo',
    href: '/axie-infinity/wiki/ban-hammer',
  },
  {
    label: 'SLP',
    href: '/axie-infinity/wiki/slp',
  },
  {
    label: 'Reinicio diario',
    href: '/axie-infinity/wiki/reset-time',
  },
  {
    label: 'Modo Aventura',
    href: '/axie-infinity/wiki/game-modes/adventure',
  },
  {
    label: 'Modo Arena',
    href: '/axie-infinity/wiki/game-modes/arena',
  },
  {
    label: 'Energía de la cuenta',
    href: '/axie-infinity/wiki/account-energy',
  },
  {
    label: 'Axie',
    href: '/axie-infinity/wiki/axie',
  },
  {
    label: 'Cartas',
    href: '/axie-infinity/wiki/cards',
  },
  {
    label: 'Buffs, debuffs y efectos especiales',
    href: '/axie-infinity/wiki/buffs-debuffs',
  },
  {
    label: 'Batallas',
    href: '/axie-infinity/wiki/match',
  },
]

export default function NavBar() {
  const [open, setOpen] = useState(false)
  return (
    <nav
      className="bg-primary bg-opacity-20 p-4 rounded cursor-pointer md:cursor-default"
      onClick={() => setOpen((value) => !value)}
    >
      Tabla de contenido
      <ul
        className={cx('md:block', {
          hidden: !open,
        })}
      >
        {options.map(({ label, href }) => (
          <li key={label}>
            <Anchor href={href}>{label}</Anchor>
          </li>
        ))}
      </ul>
    </nav>
  )
}
