import { MDXProvider } from '@mdx-js/react'

import {
  Anchor,
  Heading,
  CustomImage as Image,
  Italic,
  ListItem,
  Paragraph,
  Table,
  TableCell,
  TableHeading,
  UnorderedList,
} from '@components/axie/wiki/typography'

const components = {
  a: Anchor,
  h1: ({ children }: { children: React.ReactNode }) => (
    <Heading type="h1">{children}</Heading>
  ),
  h2: ({ children }: { children: React.ReactNode }) => (
    <Heading type="h2">{children}</Heading>
  ),
  h3: ({ children }: { children: React.ReactNode }) => (
    <Heading type="h3">{children}</Heading>
  ),
  h4: ({ children }: { children: React.ReactNode }) => (
    <Heading type="h4">{children}</Heading>
  ),
  h5: ({ children }: { children: React.ReactNode }) => (
    <Heading type="h5">{children}</Heading>
  ),
  h6: ({ children }: { children: React.ReactNode }) => (
    <Heading type="h6">{children}</Heading>
  ),
  p: Paragraph,
  ul: UnorderedList,
  li: ListItem,
  em: Italic,
  th: TableHeading,
  td: TableCell,
  table: Table,
  img: Image,
}

export default function MDX({ children }: { children?: React.ReactNode }) {
  return <MDXProvider components={components}>{children}</MDXProvider>
}
